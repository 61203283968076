// @ts-nocheck
import { graphql } from "gatsby";
import React from "react";
import PageLayout from "../components/PageLayout";

import * as Styles from "./css/site-map.module.scss";
import { goTo } from "../utilities/utils";

const SiteMap = () => {
  return (
    <>
      <PageLayout backgroundColor="#f2fafc" containerPadding="0px">
        <title>PhoneBox - Site Map</title>
        <div className="container">
          <div className={Styles.mainContainer}>
            <h3>PhoneBox Site Map</h3>
            <hr />
            <div className={Styles.innerContainer}>
              {/* <div className={Styles.singleSection}>
                <h4>Products</h4>
                <ul className={Styles.unOrderedList}>
                  <li>
                    <a
                      href="/plans"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/plans");
                      }}
                    >
                      Wireless Plans
                    </a>
                  </li>
                  <li>
                    <a
                      href="/home-internet"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/home-internet");
                      }}
                    >
                      Home Internet
                    </a>
                  </li>

                  <li>
                    <a
                      href="/plans/?plans=us"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/plans/?plans=us");
                      }}
                    >
                      United States Prepaid
                    </a>
                  </li>
                  <li>
                    <a
                      href="/plans/?type=prepaid"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/plans/?type=prepaid");
                      }}
                    >
                      Canada Monthly
                    </a>
                  </li>
                  <li>
                    <a
                      href="/plans"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/plans");
                      }}
                    >
                      Canada Prepaid
                    </a>
                  </li>
                </ul>
              </div> */}

              <div className={Styles.singleSection}>
                <h4>Activation</h4>
                <ul className={Styles.unOrderedList}>
                  <li>
                    <a href="https://esim.gophonebox.com/">eSIM</a>
                  </li>
                  <li>
                    <a
                      href="/activation"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/activation");
                      }}
                    >
                      SIM Card
                    </a>
                  </li>
                  <li>
                    <a href="https://phonebox.vmedia.ca/">
                      Home Internet Sign Up
                    </a>
                  </li>
                </ul>
              </div>
              <div className={Styles.singleSection}>
                <h4>Self Serve</h4>
                <ul className={Styles.unOrderedList}>
                  <li>
                    <a
                      href="/account"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/account");
                      }}
                    >
                      My Account
                    </a>
                  </li>
                  <li>
                    <a href="https://myaccount.gophonebox.com/login">
                      My Account - Canada
                    </a>
                  </li>
                  <li>
                    <a href="https://us.myaccount.gophonebox.com/">
                      My Account - USA
                    </a>
                  </li>
                </ul>
              </div>
              <div className={Styles.singleSection}>
                <h4>Help Center</h4>
                <ul className={Styles.unOrderedList}>
                  <li>
                    <a
                      href="/support"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/support");
                      }}
                    >
                      Support and FAQ
                    </a>
                  </li>
                  <li>
                    <a
                      href="/support"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/support");
                      }}
                    >
                      Contact us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/apn-setting"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/apn-setting");
                      }}
                    >
                      APN Settings
                    </a>
                  </li>
                </ul>
              </div>
              <div className={Styles.singleSection}>
                <h4>Partner with Us</h4>
                <ul className={Styles.unOrderedList}>
                  <li>
                    <a
                      href="/partners"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/partners");
                      }}
                    >
                      Partner Page
                    </a>
                  </li>
                  <li>
                    <a href="https://partner.gophonebox.com/">Partner Portal</a>
                  </li>
                </ul>
              </div>
              <div className={Styles.singleSection}>
                <h4>About PhoneBox</h4>
                <ul className={Styles.unOrderedList}>
                  <li>
                    <a
                      href="/about-us"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/about-us");
                      }}
                    >
                      About us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/coverage-map"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/coverage-map");
                      }}
                    >
                      Coverage Map
                    </a>
                  </li>
                  <li>
                    <a
                      href="/careers"
                      onClick={(event) => {
                        event.preventDefault();
                        goTo("/careers");
                      }}
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a href="https://blog.gophonebox.com/">Blog</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default SiteMap;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
